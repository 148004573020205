import React       from 'react';
import { graphql } from 'gatsby';
import styled      from '@emotion/styled';
import Img         from 'gatsby-image';

import CallToAction from '@interness/web-core/src/components/modules/CallToAction/CallToAction';
import HeaderImage  from '@interness/web-core/src/components/media/HeaderImage/HeaderImage';
import Heading      from '@interness/web-core/src/components/text/Heading/Heading';
import Spacer       from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Wrapper      from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import TeamMembers  from '@interness/web-core/src/components/modules/TeamMembers';

const J = styled.p`
  text-align: justify;
`;

function TeamPage(props) {
  return (
    <>
      <HeaderImage>
        <Img fluid={props.data.headerImage.childImageSharp.fluid}/>
      </HeaderImage>
      <Wrapper>
        <Spacer/>
        <section>
          <Heading>Team</Heading>
          <J>Die Müngstener Brücke verbindet die bergischen Städte Solingen und Remscheid miteinander. Wir verbinden in
            unserer Firma klassische und traditionelle Werte mit den modernen Anforderungen der heutigen Zeit an ein
            erfolgreiches, inhabergeführtes Einzelhandelsgeschäft.</J>
          <p style={{ fontWeight: 'bold', textAlign: 'center' }}>Mit unserem Motto bringen wir dies zum Ausdruck:
            Juwelier Leiber – Junges Haus mit Tradition</p>
        </section>
        <Spacer/>
        <section>
          <TeamMembers/>
        </section>
        <Spacer/>
        <section style={{ textAlign: 'center' }}>
          <CallToAction/>
        </section>
        <Spacer/>
      </Wrapper>
    </>
  )
}

export default TeamPage;

export const query = graphql`
  query TeamPageQuery {
    headerImage: file(relativePath: {eq: "headers/team.jpg"}) {
      name
      childImageSharp {
        fluid(maxWidth: 1980, maxHeight: 700, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;